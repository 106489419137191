
/* criar um componente para o markdown e mover o css para lá */
.markdown-body pre {
  border: 1px solid #ccc;
  margin: 0 15px;
}

.markdown-body blockquote {
  color: #666;
  margin: 0;
  border-left: 0.5em #eee solid;
  padding: 4px 2rem;
  margin-left: 10px;
}

.markdown-body blockquote p {
  font-size: 18px;
  margin-bottom: 0;
}

.markdown-body tr {
  border-top: 1px solid #c6cbd1;
  background: #fff;
}

.markdown-body th,
.markdown-body td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}

.markdown-body table {
  display: block;
  overflow: auto;
  width: 100%;
}

.markdown-body table tr:nth-child(2n) {
  background: #f6f8fa;
}

.markdown-body ul {
  padding-left: 2em;
}

.markdown-body blockquote,
.markdown-body dl,
.markdown-body ol,
.markdown-body p,
.markdown-body pre,
.markdown-body table,
.markdown-body ul {
  margin-bottom: 16px;
  margin-top: 0;
}

.markdown-body p {
  font-size: 17px;
  text-align: justify;
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  margin-left: 2px;
}
